import * as React from "react";

function SvgComponent() {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15.6452"
        height="11"
        rx="0.5"
        stroke="white"
      />
      <path
        d="M8.3225 6.96815L8.00431 7.35384C8.18906 7.50626 8.45594 7.50626 8.64069 7.35384L8.3225 6.96815ZM8.64069 7.35384L16.3826 0.966741L15.7462 0.195368L8.00431 6.58247L8.64069 7.35384ZM9.34873 6.22726L15.7358 11.7966L16.393 11.0429L10.0059 5.47356L9.34873 6.22726ZM0.262375 0.966741L6.59669 6.19255L7.23307 5.42117L0.898758 0.195368L0.262375 0.966741ZM6.59669 6.19255L8.00431 7.35384L8.64069 6.58247L7.23307 5.42117L6.59669 6.19255ZM6.58328 5.43264L0.248966 11.0455L0.912167 11.794L7.24648 6.18108L6.58328 5.43264Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
